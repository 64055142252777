.nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    z-index: 999;
}
.logo{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.logoImg{
    height: 80px;
    width: 80px;
}

.logoText{
    font-size: 30px;
    margin-left: -15px;
}

.hamburger{
    font-size:40px;
    
}
.navMenu{
    display: flex;
    align-items: center;
}

.menuBar{
    display: none;
    align-items: center;
    margin-right: 20px;
    color:white;
}

.navLink{
    margin-right: 20px;
    font-size: 20px;
    cursor: pointer;
}
.navRight{
    margin-right: 20px;
    font-size: 20px;
    color:white;
}
.navRightButton{
    color:white;
    /* background-image: linear-gradient(to right top, #5a1bb1, #6b23bc, #7b2ac7, #8b32d1, #9b3adc); */
    padding: 5px 15px 5px 15px ;
    border-radius: 25px;
    border:2px solid;
}

.selected{
    color: white;
    text-decoration: none;
}

@media screen and (max-width:768px){
    .menuBar{
        display:block;
        align-items: center;
        cursor:pointer;
    }
    .navMenu{
        display: none;
    }
    .navRight{
        display: none;
    }
}