.hero-wrapper{
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: center;
    height: 90vh;
}
.hero-content{
    margin: 0;
}
.hero-title{
    font-size: 55px;
    text-align: center
}
.hero-desc{
    margin-top:20px;
    font-size: 25px;
    color:grey;
    text-align: center;
}
.hero-btn{
    text-align: center;
    margin-top: 40px;
    font-size: 22px;
    cursor: pointer;
}
.hero-btn-txt{
     background-image: linear-gradient(to right top, #5a1bb1, #6b23bc, #7b2ac7, #8b32d1, #9b3adc); 
     /* background-color: #6b23bc; */
     padding: 5px 15px 5px 15px ;
     border-radius: 25px;
     cursor: pointer;
     
}
.hero-btn-txt:hover{
    background:white;
    border: 2px solid #5a1bb1;
    color:#5a1bb1;
    padding: 5px 15px 5px 15px ;
    border-radius: 25px;
    cursor: pointer;
}

@media screen and (max-width:768px){
    .hero-content{
        margin-top: -100px;
    }

    .hero-title{
        font-size: 35px;
        text-align: center
    }
    .hero-desc{
        margin-top:20px;
        font-size: 18px;
        color:grey;
        text-align: center;
    }
    .hero-btn{
        text-align: center;
        margin-top: 30px;
        font-size: 20px;
        
    }
}