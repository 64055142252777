.description-content{
    margin-top: 50px;
    line-height: 1.8;
}

.title{
    color: rgb(50,50,50);
    font-size: 20px;
    font-weight: 500;
}
.content{
    font-size: 15px;
    color:grey;
    margin-bottom: 30px;
}
@media screen and (max-width:768px){
    .description-content{
        margin-top: 20px;

    }
    .content{
        margin-bottom: 20px;
    }
}
