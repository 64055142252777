.postjob-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 500px;
    
}
.postjob-content{
    align-items: center;
    display: flex;
    flex-direction: column;
    /* background-image: linear-gradient(to right top, rgb(90, 27, 177,0.3), rgb(107, 35, 188,0.3), rgb(123, 42, 199,0.3), rgb(139, 50, 209,0.3), rgb(155, 58, 220,0.3)); */
    width: 70%;
    height: 90%;
    border-radius: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.4) 0px 30px 60px -30px, rgba(10, 37, 64, 0.7) 0px -2px 6px 0px inset;
}

.postjob-img{
    align-items: center;
    width: 300px;
    margin-top: -50px;
    margin-bottom: -50px;
}
.postjob-title{
    font-size: 40px;
    font-weight: 500;
    color: rgb(79, 78, 78);
}
.postjob-desc{
    font-size: 18px;
    padding: 20px;
    color: rgb(109, 109, 109);
    text-align: center;
}
.postjob-btn{
    margin-top: 20px;
    font-size: 20px;
    background: linear-gradient(to right top, #5a1bb1, #6b23bc, #7b2ac7, #8b32d1, #9b3adc);
    color:white;
    border:2px solid;
    border-radius: 50px;
    padding: 10px 40px;
    cursor: pointer;
}


@media screen and (max-width:768px){
    .postjob-img{
        width: 250px;
        
    }
    .postjob-content{
        width: 90%;
    }
    .postjob-title{
        font-size: 35px;
    }
    .postjob-desc{
        font-size: 15px;
    }
    .postjob-btn{
        padding: 8px 32px;
    }
   
    
}
