.jobboard-wrapper{
    display:flex;
    padding:10px;
    height:100%
}
.left{
    flex:1.5;
    
}
.center{
    flex:10; 
    /* background-color: antiquewhite; */
}
.center-top{
    width: 100%;
    display: flex;
    align-items: center;
    margin-left: 9%;
    margin-bottom: 10px;
    /* background-color: aqua; */
}
.center-jobs{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.more-job-btn{
    background-color: transparent;
    border: none;
    font-size: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #5a1bb1;
    cursor: pointer;
}

.right{
    flex:1.5;

}


@media screen and (max-width:768px){
    .left{
        display: none;
  
    }
    .center{
        flex:10; 
    }
    .right{
        display: none;
    }
    .center-top{
        width: 100%;
        display: flex;
        align-items: center;
        margin-left: 1%;
        margin-bottom: 10px;
        /* background-color: aqua; */
    }
    .alert{
        height: 25px;
    }
    .alert-text{
        
        margin-bottom: 5px;
    }
}