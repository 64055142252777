.keyword{
    margin-right: 10px;
    color:grey;
    border: 1px solid;
    padding:5px;
    border-radius:7px;
    font-size: 15px;
}

@media screen and (max-width:768px){
    .keyword{
        margin-right: 5px;
        font-size: 10px;
        padding: 2px;
    }
}
