.discord-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 500px;
}
.discord-content{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(55, 55, 55);
    width: 70%;
    height: 90%;
    border-radius: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.4) 0px 30px 60px -30px, rgb(4, 11, 19) 0px -2px 6px 0px inset;
}
.discord-img{
    align-items: center;
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
    margin-top: -20px;
}

.discord-title{
    font-size: 40px;
    font-weight: 500;
    color: white;
}
.discord-desc{
    font-size: 18px;
    padding: 20px;
    color: rgb(200, 200, 200);
    text-align: center;
}
.discord-btn{
    margin-top: 20px;
    font-size: 20px;
    background: rgb(55, 55, 55);
    color:white;
    border-radius: 50px;
    border:2px solid;
    padding: 10px 40px;
    cursor: pointer;
}


@media screen and (max-width:768px){
    .discord-img{
        width: 180px;
        height: 180px;
        
    }
    .discord-content{
        width: 90%;
    }
    .discord-title{
        font-size: 35px;
    }
    .discord-desc{
        font-size: 15px;
    }
    .discord-btn{
        padding: 8px 32px;
    }
   
    
}