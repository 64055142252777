.jobtile-container{
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 150px;
    padding: 10px;
    margin-bottom: 10px;
    overflow:hidden;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.extra{
    display: flex;
    align-items: center;
    justify-content: space-between  ;
    margin-right: 14%;
}
.applybtn{
    margin-left:20px;
}
.jobtile-top{
    width: 100%;
    display: flex;
    align-items: center;
}
.company-logo{
    margin-right: 20px;
}
.company-logo-img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-size: cover;
    object-fit: cover;
}
.position-wrapper{
    width: 80%;
    
}
.position-title{
    font-size: 25px;
    font-weight: 600;
}
.position-employer{
    font-size: 18px;
    font-weight: 400;
    color:rgb(101, 100, 100);
}
.date-posted{
    margin-left: 50;
    font-size: 12px;
    font-weight: 500;
    color:rgb(101, 100, 100);
}
.jobtile-center{
    margin-top: 20px;
    margin-left:80px;
    width:100%;
    margin-right: 20px;
}
.category-info{
    display: flex;
    align-items: center;
}
.job-domain{
    margin-right: 10px;
    font-size: 15px;
    color:grey;
}
.dot{
    background-color: grey;
    color:grey;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    margin-right: 10px;
}
.job-keywords{
    margin-top: 10px;
    display: flex;
    margin-bottom: 10px;
    
}
.apply-btn{
    background: linear-gradient(to right top, #5a1bb1, #6b23bc, #7b2ac7, #8b32d1, #9b3adc);
    padding: 5px 15px 5px 15px ;
    border-radius: 25px;
    cursor: pointer;
    color:white;
}
.apply-btn:hover{
    background:white;
    border: 2px solid #5a1bb1;
    color:#5a1bb1;
    padding: 5px 15px 5px 15px ;
    border-radius: 25px;
    cursor: pointer;
}

@media screen and (max-width:768px){
    .jobtile-container{
        width: 95%;
        margin-left: 10px;
        margin-right: 10px;
        height: 130px;
    }
    .company-logo{
        margin-right: 10px;
    }
    .company-logo-img{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-size: cover;
        object-fit: cover;
    }
    .position-wrapper{
        width: 80%;
        margin-right: 20px;
    }
    .position-title{
        font-size: 18px;
        font-weight: 600;
    }
   
    .position-employer{
        font-size: 13px;
        font-weight: 400;
        color:rgb(101, 100, 100);
    }
    .date-posted{
        font-size: 12px;
        font-weight: 500;
        color:rgb(101, 100, 100);
    }
    .jobtile-center{
        margin-top: 10px;
        margin-left:50px;
        width:100%;
    }
    .category-info{
        display: flex;
        align-items: center;
    }
    .job-domain{
        margin-right: 2px;
        font-size: 12px;
        color:grey;
    }
    .dot{
        background-color: grey;
        color:grey;
        height: 2px;
        width: 2px;
        border-radius: 50%;
        margin-right: 2px;
    }
   
    .job-keywords{
        display: none;
    }
    .apply-btn{
        margin-top: 15px;
        font-size: 15px;
        padding: 5px 10px 5px 10px ;
        border-radius: 25px;
        cursor: pointer;
        color:white;
    }
}


