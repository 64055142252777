.topbar{
    background-color: black;
    color:white;
    position: sticky;
    top:0;
    margin-bottom: 50px;
    height: 75px;
}
.learn-desc{
    margin: auto;
    width: 70%;
    margin-bottom: 30px;
}
.learn-title{
    text-align: center;
    font-size: 30px;
    font-weight: 400;
    color:navy;
}
.learn-text{
    margin-top: 10px;
    text-align: center;
    color:rgb(93, 91, 91);
}
.learn-content{
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    width: 70%;
}

@media screen and (max-width:768px){
    .learn-content{
        display: flex;
        flex-direction: column-reverse;
    }
    
}