.topbar{
    background-color: black;
    color:white;
    position: sticky;
    top:0;
    margin-bottom: 20px;
    height: 75px;
}
.job-content{
    display: flex;
    padding: 20px;
}
.content-col-1{
    width: 60%;
}
.job-title{
    font-size:40px;
    font-weight: 500;
    color: black;
    margin-bottom: 10px;
}
.logo-employer{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.job-logo-img{
    width: 50px;
    height:50px;
    object-fit: cover;
    margin-right: 20px;
}

.job-employer{
    font-size: 20px;
    font-weight: 500;
    color:rgb(50, 50, 50);
    
}
.job-info{
    display: flex;
    align-items: center;
}
.job-info-txt{
    font-size: 20px;
    margin-right: 20px;
    color:grey;
}
.dot-sep{
    background-color: grey;
    color:grey;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    margin-right: 20px;
}

.job-keywords{
    margin-top: 20px;
}

.job-apply-btn{
    margin-top: 20px;
    font-size: 20px;
    text-decoration: none;
    background: linear-gradient(to right top, #5a1bb1, #6b23bc, #7b2ac7, #8b32d1, #9b3adc);
    padding: 5px 15px 5px 15px ;
    border-radius: 25px;
    cursor: pointer;
    color:white;
    border:none;
}
.catchy-title{
    margin-left: 5px;
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: 500;
    color:green;
}

@media screen and (max-width:768px){
    .content-col-1{
        width: 100%;
    }
    .job-title{
        font-size:25px;
    }
    .job-logo-img{
        width: 40px;
        height:40px;
        margin-right: 10px;
    }
    .job-employer{
        font-size: 15px;
    }
    .job-info-txt{
        font-size: 12px;
        margin-right: 5px;
        color:grey;
    }
    .logo-employer{
        margin-bottom: 10px;
    }
    .dot-sep{
        background-color: grey;
        color:grey;
        height: 2px;
        width: 2px;
        border-radius: 50%;
        margin-right: 5px;
    }
    .job-apply-btn{
        font-size: 15px;
    }
    .content-col-2{
        display: none;
    }
}
