
.navbar{
    background-color: black;
    color:white;
    position: sticky;
    top:0;
    height: 75px;
    z-index: 999;
}
.section-1{
    background-color: black;
    color:white;
    height: 100vh;
}
.section-2{
    color: black;

}
.section2-title{
    margin: 30px 10px 0 10px;
    font-size: 30px;
    font-weight: 500;
    color: navy;
    text-align: center;
}
.section2-filter{
    display: flex;
    width: 50%;
    margin-left: 20%;
}
.section-3{
    margin-top: 40px;
    
}
.section-4{
    margin-top: 40px;
}
.footer{
    margin-top: 20px;
    height:40px;
    text-align: center;
}
.filter-title{
    width: 50%;
    margin: auto;
}
.filter-select{
    width: 200px;
    margin-right: 20px;
}
