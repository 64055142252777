.tutorialtile-container{
    width:300px;
    height: 380px;
    margin-right: 30px;
    margin-bottom: 30px;
    border-radius: 15px;
    background-color: rgb(160, 160, 160);
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    cursor: pointer;
}
.tutorialtile-container:hover{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.tutorial-img{
    border-radius: 15px;
    height:60%;
}
.tutorial-img-src{
    border-radius: 15px 15px 0 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.link-logo{
   display: flex;
   margin: 10px 0 0 10px;
   align-items: center;
}

.logo-img{
    height:30px;
    width:30px;
    border-radius: 50%;
    object-fit: cover;
}
.tutorial-source{
    margin: 0 0 0 10px;
    color:white;
    font-size: 15px;
}
.tutorial-title{
    margin: 5px 0 0 10px;
    font-weight: 500;
    font-size: 18px;
    color:white
}
.tutorial-desc{
    margin: 5px 0 0 10px;
    font-size: 15px;
    color:rgb(216, 215, 215);
}


@media screen and (max-width:768px){
    .tutorialtile-container{
        width: 100%;
    }
    
}